import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import Logo from "../images/seyranlogo.png";
import { ReactComponent as SelectiveLogo } from "../images/SelectiveLightSVG.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const EmptyScreen = ({ loading, setLoading }) => {
  const [datas, setDatas] = useState([]);
  const [authorize, setAuthorize] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [tempDatas, setTempDatas] = useState([]);

  const handleData = async () => {
    setLoading(true);
    const { data } = await axios.get(
      "https://api.selectiveyazilim.com/cemrekoltuk/test/data"
    );
    setDatas(data);
    setTempDatas(data);
    setLoading(false);
  };

  useEffect(() => {
      const params = window.location.search;
      const para = new URLSearchParams(params);
      const isLogged = para.get("isLogged");

      if(isLogged) {
        setAuthorize(true);
      }
    handleData();
  }, []);

  const onSearch = (text) => {
    let newData = [];
    if (!text) {
      newData = tempDatas;
    } else {
      newData = tempDatas.filter((item) => {

          if (String(String(item.sipno)+'.'+String(item.sipsr)).indexOf(text.toLowerCase()) !== -1) {
              return true;
          } else if (item.firma.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
          return true;
        } else if (
          item.urunadi.toLowerCase().indexOf(text.toLowerCase()) !== -1
        ) {
          return true;
        } else if (
          item.stkno.toLowerCase().indexOf(text.toLowerCase()) !== -1
        ) {
          return true;
        } else if (
          item.musadi.toLowerCase().indexOf(text.toLowerCase()) !== -1
        ) {
          return true;
        } else return false;
      });
    }

    setDatas(newData);
  };

  const handleAuth = async () => {
    // "https://api.selectiveyazilim.com/seyran/test/login"
    if (username && password) {
      axios
        .post("https://api.selectiveyazilim.com/cemrekoltuk/test/login", {
          username,
          password,
        })
        .then((data) => {
          setAuthorize(true);
          toast.success("Giriş Başarılı");
        })
        .catch((err) => {
          toast.error("Kullanıcı adı veya şifre hatalı", {
            type: "error",
          });
        });
    } else {
      toast("Kullanıcı adı ve şifre boş bırakılamaz!", {
        type: "error",
      });
    }
  };

  if (!authorize) {
    return (
      <>
        <div
          className="app-second"
          style={{
            minHeight: "100vh",
          }}
        >
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
            }}
          >
            <SelectiveLogo />
            <span
              style={{
                marginTop: 30,
                fontSize: 70,
                fontWeight: "600",
                color: "white",
                fontFamily: "Josefin Sans",
              }}
            >
              selective
            </span>
          </div>
          <div
            className="login-container"
            style={{
              maxWidth: 400,
              display: "flex",
              flexDirection: "column",
              gap: 15,
            }}
          >
            <span style={{
                fontSize:40,
                fontWeight:'bold'
            }}>CEMRE KOLTUK</span>
            <input
              placeholder="Kullanıcı Adı"
              onChange={(e) => setUsername(e.target.value)}
              style={{
                backgroundColor: "rgba(255,255,255,0.6)",
                border: 0,
                borderRadius: 10,
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                width: "100%",
              }}
            />
            <input
              placeholder="Şifre"
              type={"password"}
              onChange={(e) => setPassword(e.target.value)}
              style={{
                backgroundColor: "rgba(255,255,255,0.6)",
                border: 0,
                borderRadius: 10,
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                width: "100%",
              }}
            />
            <button
              onClick={(e) => handleAuth()}
              style={{
                backgroundColor: "rgba(255,255,255,1)",
                border: 0,
                borderRadius: 10,
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                width: "100%",
                cursor: "pointer",
              }}
            >
              Giris Yap
            </button>
          </div>
        </div>
        <ToastContainer />
      </>
    );
  } else
    return (
      <div className="app-third" style={{}}>
        <div
          style={{
            position: "absolute",
            zIndex: 1,
          }}
        >
          <SelectiveLogo
            style={{
              width: "70vh",
              height: "70vh",
            }}
          />
        </div>
        <div
          style={{
            minWidth: "100vw",
            minHeight: "100vh",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "rgba(31, 40, 56, 0.7)",
            zIndex: 2,
          }}
        >
          <div
            style={{
              marginBottom: 10,
              display: "flex",
              width: "100%",
            }}
          >
            <input
              placeholder="Ara..."
              onChange={(e) => onSearch(e.target.value)}
              style={{
                backgroundColor: "rgba(255,255,255,0.6)",
                border: 0,
                borderRadius: 10,
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                width: "100%",
              }}
            />
          </div>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              display: "grid",
              gridTemplateColumns: "0.7fr 0.7fr 0.7fr 0.7fr",
              gridGap: "10px",

              overflowY: "auto",
            }}
          >
            {datas.map((item) => (
              <div
                onClick={() => {
                  // const sipno = para.get("sipno");
                  // const sipsr = para.get("sipsr");
                  // const musadi = para.get("musadi");
                  // const firma = para.get("firma");
                  // const mail = para.get("mail");
                  // const tarih = para.get("tarih");
                  // const urunadi = para.get("urunadi");
                  window.location.href =
                    "?sipno=" +
                    item.sipno +
                    "&sipsr=" +
                    item.sipsr +
                    "&musadi=" +
                    item.musadi +
                    "&firma=" +
                    item.firma +
                    "&mail=" +
                    item.mail +
                    "&tarih=" +
                    item.tarih +
                    "&urunadi=" +
                    item.urunadi;
                }}
                style={{
                  borderRadius: 10,
                  padding: 8,
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "rgba(255,255,255,0.7)",

                  fontSize: 15,
                  fontWeight: 700,
                  cursor: "pointer",
                }}
              >
                <span>Müşteri Adı: {item.musadi}</span>
                <span>Ürün Adı: {item.urunadi}</span>
                <span>Firma: {item.firma}</span>
                <span>Stok No: {item.stkno}</span>
                  <span>Sip-No/Sip-Sr: {item.sipno + '.'+ item.sipsr}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
};

export default EmptyScreen;
