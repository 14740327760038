import React, {useEffect} from "react";
import Modal from "react-modal";
import { Camera } from "react-camera-pro";
import { BiExit } from "react-icons/bi";
import { IoCameraReverseOutline } from "react-icons/io5";
import { AiFillCamera } from "react-icons/ai";

function CameraModal({ setIsOpen, isOpen, setImages, images }) {
  const camera = React.useRef();

  useEffect(() => {

      if(isOpen) {
          setTimeout(() => {
              try {
                  camera?.current?.switchCamera()
              }catch(err) {

              }
          },1000)

      }
  },[isOpen,setIsOpen])

  const closeModal = () => {};
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "100vw",
      height: "100vh",
      backgroundColor: "white",
      transform: "translate(-50%, -50%)",
      maxHeight: "90vh",
      maxWidth: "90vw",

    },
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
      <div style={{ width: "100%", height: "100%", margin: 0, padding: 0 }}>
        <Camera ref={camera} aspectRatio={16 / 9} />
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          maxWidth: "100%",
          width: "100%",
          height: 90,
          display: "flex",
          flexDirection: "row",
          backgroundColor: "rgba(0,0,0,0.6)",
        }}
      >
        <button
          onClick={() => setIsOpen(false)}
          style={{
            height: 90,
            width: 90,
            backgroundColor: "white",
            border: 0,
            borderRadius: 99,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: 70,
              height: 70,
              backgroundColor: "rgba(0,0,0,0.5)",
              borderRadius: "100%",
              fontSize: 40,
              color: "white",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <BiExit />
          </div>
        </button>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            maxWidth: "30%",
            overflow: "auto",
            flexWrap: "nowrap",
          }}
        >
          {images.map((image) => {
            return (
              <img
                src={image}
                style={{ height: 80, width: 80, marginLeft: 10 }}
              />
            );
          })}
        </div>
        <button
          onClick={() => camera.current.switchCamera()}
          style={{
            height: 90,
            width: 90,
            backgroundColor: "white",
            border: 0,
            borderRadius: 99,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: 70,
              height: 70,
              backgroundColor: "rgba(0,0,0,0.5)",
              borderRadius: "100%",
              fontSize: 40,
              color: "white",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <IoCameraReverseOutline />
          </div>
        </button>
        <button
          onClick={() => setImages([...images, camera.current.takePhoto()])}
          style={{
            height: 90,
            width: 90,
            backgroundColor: "white",
            border: 0,
            borderRadius: 99,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
          }}
        >
          <div
            style={{
              width: 70,
              height: 70,
              backgroundColor: "rgba(0,0,0,0.5)",
              borderRadius: "100%",
              fontSize: 40,
              color: "white",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <AiFillCamera />
          </div>
        </button>
      </div>
    </Modal>
  );
}

export default CameraModal;
