import React from "react";
import Modal from "react-modal";
import { Camera } from "react-camera-pro";
import { BiExit } from "react-icons/bi";
import { IoCameraReverseOutline } from "react-icons/io5";
import { AiFillCamera } from "react-icons/ai";

function DetailsModal({ setIsOpen, isOpen, image }) {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "100vw",
      height: "100vh",
      backgroundColor: "white",
      transform: "translate(-50%, -50%)",
      maxHeight: "100vh",
    },
  };

  return (
    <Modal isOpen={isOpen} style={customStyles}>
      <img style={{ width: "100%", height: "100%" }} src={image} />
      <div
        style={{
          position: "absolute",
          right: 30,
          bottom: 30,
          width: 130,
          height: 130,
          backgroundColor: "black",
          borderRadius: 99,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <button
          onClick={() => setIsOpen(false)}
          style={{
            border: 0,
            width: "80%",
            height: "80%",
            borderRadius: 99,
            backgroundColor: "rgba(255,255,255,0.7)",
            color: "black",
            fontSize: 60,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <BiExit />
        </button>
      </div>
    </Modal>
  );
}

export default DetailsModal;
